<template>
  <form class="space-y-6"
        @submit.prevent="login">
    <div class="space-y-1">
      <inputs-label for="email_address" title="Email Address"/>

      <inputs-email for="email" placeholder="Your email address" required @set="form.email = $event.target.value"/>
    </div>

    <div class="space-y-1">
      <inputs-label for="password" title="Password"/>

      <inputs-password for="password" required @set="form.password = $event.target.value"/>
    </div>

    <button :class="{'opacity-50 cursor-not-allowed': disable_login_button}" :disabled="disable_login_button"
            class="btn-primary-md w-full"
            type="submit">
      Login
    </button>
  </form>
</template>

<script setup>
import {useApi} from "~/composables/api.js"

const form = reactive({
  email: '',
  password: '',
})

const disable_login_button = ref(false)

function login(e) {
  disable_login_button.value = true

  useApi('auth/login', {
    method: 'POST',
    body: JSON.stringify(form),
  })
      .then(response => {
        const token = useCookie('token', {maxAge: 60 * 60 * 24 * 365})
        token.value = response.token

        location.reload()
      })
      .catch(error => {
        disable_login_button.value = false
      })
}
</script>
