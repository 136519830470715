<template>
  <container-main>
    <div class="mx-auto max-w-lg">
      <h1 class="text-center text-2xl font-bold text-indigo-600 sm:text-3xl">
        Get started today
      </h1>

      <p class="mx-auto mt-4 max-w-md text-center text-gray-500">
        Manage your RSS feeds and hack the social media algorithm.
      </p>

      <div class="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
        <forms-auth-login/>

        <p class="text-center text-sm text-gray-500">
          <nuxt-link class="link" to="/forgot/password">
            Did you forget your password?
          </nuxt-link>
        </p>
      </div>
    </div>
  </container-main>
</template>

<script setup>
definePageMeta({
  middleware: 'guest',
})

const route = useRoute()
const router = useRouter()

onMounted(async () => {
  if (route.query.token) {
    const token = useCookie('token', {
      maxAge: 60 * 60 * 24 * 30,
    })
    token.value = route.query.token

    let query = Object.assign({}, route.query)
    delete query.token

    await router.replace({query})

    location.reload()
  }
})
</script>
